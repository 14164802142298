<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">LEAVE OF ABSENCE INFORMATION</div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any current Worker’s Comp Claims pending?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="leaveInfo_workerscompclaimpending" id="leaveInfo_workerscompclaimpendingYes" value="YES" [(ngModel)]="leaveInfo.workersCompClaimPending"/>
            <label class="form-check-label col-form-label-sm" for="leaveInfo_workerscompclaimpendingYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="leaveInfo_workerscompclaimpendingexplanation" placeholder="If yes, explain" [disabled]="leaveInfo.workersCompClaimPending==='NO'"  [(ngModel)]="leaveInfo.workersCompClaimPendingExplanation" name="leaveInfo_workerscompclaimpendingexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="leaveInfo_workerscompclaimpending" id="leaveInfo_workerscompclaimpendingNo" value="NO" [(ngModel)]="leaveInfo.workersCompClaimPending" />
            <label class="form-check-label col-form-label-sm" for="leaveInfo_workerscompclaimpendingNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any employees currently on any type of Leave? STD, LTD, Maternity, Leave of Absence?</label>
    <div class="col-sm-9">
      <div class='employeeLeaveItem' *ngFor="let emp of leaveInfo.employeesOnLeave; let i=index">
        <div class="form-group row">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Name</div>
            </div>
            <input type="text" class="form-control form-control-sm" id="leaveinfo_employeesonleave_name-1{{i}}" placeholder=""  [(ngModel)]="emp.name" />

            <div class="input-group-prepend">
              <div class="input-group-text">Last day of work</div>
            </div>
            <input type="text" class="form-control form-control-sm" id="leaveinfo_employeesonleave_ldow-1{{i}}" placeholder=""  [(ngModel)]="emp.lastDayOfWork" />
            <div class="input-group-prepend">
              <div class="input-group-text">Expected return day</div>
            </div>
            <input type="text" class="form-control form-control-sm" id="leaveinfo_employeesonleave_erd-1{{i}}" placeholder=""  [(ngModel)]="emp.expectedReturnDate" />
            <div class="col text-right">
              <button type="submit" (click)="removeEmployeeToList(i)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
            </div>
          </div>
        </div>
      </div>
      <button style="margin-top:5px" type="submit" (click)="addEmployeeToList()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you handle leaves in-house or outsourced?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="leaveinfo_leaveshandletype" [(ngModel)]="leaveInfo.leavesHandleType"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you offer parental leave? If so, please explain the benefit.</label>
<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="leaveinfo_parentalleavebenefit" [(ngModel)]="leaveInfo.parentalLeaveBenefit"/>-->
<!--    </div>-->

    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="leaveinfo_parentalleavebenefitYes" id="leaveinfo_parentalleavebenefitYes" value="YES" [(ngModel)]="leaveInfo.parentalLeaveBenefitYesNo"/>
            <label class="form-check-label col-form-label-sm" for="leaveinfo_parentalleavebenefitYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="leaveinfo_parentalleavebenefit" placeholder="If yes, explain" [disabled]="leaveInfo.parentalLeaveBenefitYesNo==='NO'"  [(ngModel)]="leaveInfo.parentalLeaveBenefit" name="leaveinfo_parentalleavebenefitexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="leaveinfo_parentalleavebenefitNo" id="leaveinfo_parentalleavebenefitNo" value="NO" [(ngModel)]="leaveInfo.parentalLeaveBenefitYesNo" />
            <label class="form-check-label col-form-label-sm" for="leaveinfo_parentalleavebenefitNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
