<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">EMPLOYEE INFORMATION</div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide annual employee reviews? If yes, who provides reviews?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_annualReviewsYes" id="employeeinfo_annualReviewsYes" value="YES" [(ngModel)]="employeeInfo.annualReviewsYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_annualReviewsYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_annualReviews" placeholder="If yes, explain" [disabled]="employeeInfo.annualReviewsYesNo==='NO'"  [(ngModel)]="employeeInfo.annualReviews" name="employeeinfo_annualReviewsexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_annualReviewsNo" id="employeeinfo_annualReviewsNo" value="NO" [(ngModel)]="employeeInfo.annualReviewsYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_annualReviewsNo">No</label>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_annualReviews" [(ngModel)]="employeeInfo.annualReviews"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is there documentation of review in employee file?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_documentationInEmployeeFile" [(ngModel)]="employeeInfo.documentationInEmployeeFile"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who are your key employees, managers or supervisors?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_keyEmployeesManagers" [(ngModel)]="employeeInfo.keyEmployeesManagers"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What shifts do employees typically work?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_typicalShifts" [(ngModel)]="employeeInfo.typicalShifts"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are you currently hiring any positions? If yes what positions and how many openings?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_hiringPositionsAmountYes" id="employeeinfo_hiringPositionsAmountYes" value="YES" [(ngModel)]="employeeInfo.hiringPositionsAmountYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_hiringPositionsAmountYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringPositionsAmount" placeholder="If yes, explain" [disabled]="employeeInfo.hiringPositionsAmountYesNo==='NO'"  [(ngModel)]="employeeInfo.hiringPositionsAmount" name="employeeinfo_hiringPositionsAmountexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_hiringPositionsAmountNo" id="employeeinfo_hiringPositionsAmountNo" value="NO" [(ngModel)]="employeeInfo.hiringPositionsAmountYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_hiringPositionsAmountNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringPositionsAmount" [(ngModel)]="employeeInfo.hiringPositionsAmount"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How long has each position been posted?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringPositionsPostDuration" [(ngModel)]="employeeInfo.hiringPositionsPostDuration"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have any contracts with any recruiting agencies?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_contractsWithHiringAgenciesYes" id="employeeinfo_contractsWithHiringAgenciesYes" value="YES" [(ngModel)]="employeeInfo.contractsWithHiringAgenciesYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_contractsWithHiringAgenciesYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_contractsWithHiringAgencies" placeholder="If yes, explain" [disabled]="employeeInfo.contractsWithHiringAgenciesYesNo==='NO'"  [(ngModel)]="employeeInfo.contractsWithHiringAgencies" name="employeeinfo_contractsWithHiringAgenciesexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_contractsWithHiringAgenciesNo" id="employeeinfo_contractsWithHiringAgenciesNo" value="NO" [(ngModel)]="employeeInfo.contractsWithHiringAgenciesYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_contractsWithHiringAgenciesNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_contractsWithHiringAgencies" [(ngModel)]="employeeInfo.contractsWithHiringAgencies"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your hiring budget per position?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringBudgetPerPostion" [(ngModel)]="employeeInfo.hiringBudgetPerPostion"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How long does it take you typically fill your Vet positions?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_typicalDurationToFillVetPostion" [(ngModel)]="employeeInfo.typicalDurationToFillVetPostion"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How about your Licensed Tech positions?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_licensedTechPostions" [(ngModel)]="employeeInfo.licensedTechPostions"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who does the hiring?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringResponsible" [(ngModel)]="employeeInfo.hiringResponsible"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do any employees have a company credit card? If yes, list who currently has credit cards?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_companyCreditCardHoldersYes" id="employeeinfo_companyCreditCardHoldersYes" value="YES" [(ngModel)]="employeeInfo.companyCreditCardHoldersYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_companyCreditCardHoldersYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_companyCreditCardHolders" placeholder="If yes, explain" [disabled]="employeeInfo.companyCreditCardHoldersYesNo==='NO'"  [(ngModel)]="employeeInfo.companyCreditCardHolders" name="employeeinfo_companyCreditCardHoldersexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_companyCreditCardHoldersNo" id="employeeinfo_companyCreditCardHoldersNo" value="NO" [(ngModel)]="employeeInfo.companyCreditCardHoldersYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_companyCreditCardHoldersNo">No</label>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_companyCreditCardHolders" [(ngModel)]="employeeInfo.companyCreditCardHolders"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is there a Union or Collective Bargaining Agreement in place?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_bargainingAgreementYes" id="employeeinfo_bargainingAgreementYes" value="YES" [(ngModel)]="employeeInfo.bargainingAgreementYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_bargainingAgreementYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_bargainingAgreement" placeholder="If yes, explain" [disabled]="employeeInfo.bargainingAgreementYesNo==='NO'"  [(ngModel)]="employeeInfo.bargainingAgreement" name="employeeinfo_bargainingAgreementexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_bargainingAgreementNo" id="employeeinfo_bargainingAgreementNo" value="NO" [(ngModel)]="employeeInfo.bargainingAgreementYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_bargainingAgreementNo">No</label>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_bargainingAgreement" [(ngModel)]="employeeInfo.bargainingAgreement"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you offer Tuition Reimbursement or Student Loan Repayment?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_tuitionReimbursementYes" id="employeeinfo_tuitionReimbursementYes" value="YES" [(ngModel)]="employeeInfo.tuitionReimbursementYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_tuitionReimbursementYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_tuitionReimbursement" placeholder="If yes, explain" [disabled]="employeeInfo.tuitionReimbursementYesNo==='NO'"  [(ngModel)]="employeeInfo.tuitionReimbursement" name="employeeinfo_tuitionReimbursementexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_tuitionReimbursementNo" id="employeeinfo_tuitionReimbursementNo" value="NO" [(ngModel)]="employeeInfo.tuitionReimbursementYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_tuitionReimbursementNo">No</label>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_tuitionReimbursement" [(ngModel)]="employeeInfo.tuitionReimbursement"/>-->
<!--    </div>-->
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you offer pet insurance or other pet benefits to employees?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_petInsuranceOrBenefitYes" id="employeeinfo_petInsuranceOrBenefitYes" value="YES" [(ngModel)]="employeeInfo.petInsuranceOrBenefitYesNo"/>
            <label class="form-check-label col-form-label-sm" for="employeeinfo_petInsuranceOrBenefitYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="employeeinfo_petInsuranceOrBenefit" placeholder="If yes, explain" [disabled]="employeeInfo.petInsuranceOrBenefitYesNo==='NO'"  [(ngModel)]="employeeInfo.petInsuranceOrBenefit" name="employeeinfo_petInsuranceOrBenefitexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="employeeinfo_petInsuranceOrBenefitNo" id="employeeinfo_petInsuranceOrBenefitNo" value="NO" [(ngModel)]="employeeInfo.petInsuranceOrBenefitYesNo" />
            <label class="form-check-label col-form-label-sm" for="employeeinfo_petInsuranceOrBenefitNo">No</label>
          </div>
        </div>
      </div>
    </div>


<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="employeeinfo_petInsuranceOrBenefit" [(ngModel)]="employeeInfo.petInsuranceOrBenefit"/>-->
<!--    </div>-->
  </div>
</div>
