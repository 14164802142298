import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HrformService {

  constructor(private http: HttpClient) { }

  saveForm(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/hrform', data);
  }
  saveNHRForm(data): Observable<HrFormN> {
    return this.http.post<HrFormN>(GlobalConstants.apiURL+'/api/onboarding/form/nhrform', data);
  }

  savePayrollForm(data): Observable<HrPayrollForm> {
    return this.http.post<HrPayrollForm>(GlobalConstants.apiURL+'/api/onboarding/form/hrpayrollform', data);
  }

  getProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
  }

  getFormData(projectId:string, userName:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/hrform/'+projectId+'/'+userName);
  }
  getNHRFormData(projectId:string, userName:string): Observable<HrFormN> {
    return this.http.get<HrFormN>(GlobalConstants.apiURL+'/api/onboarding/form/nhrform/'+projectId+'/'+userName);
  }

  getPayrollFormData(projectId:string, userName:string): Observable<HrPayrollForm> {
    return this.http.get<HrPayrollForm>(GlobalConstants.apiURL+'/api/onboarding/form/hrpayrollform/'+projectId+'/'+userName);
  }

}
export interface DayEnablementWithNote {
  enabled: boolean,
  note: string
}
export interface WeekCheck {
  sunday?: DayEnablementWithNote;
  monday?: DayEnablementWithNote;
  tuesday?: DayEnablementWithNote;
  wednesday?: DayEnablementWithNote;
  thursday?: DayEnablementWithNote;
  friday?: DayEnablementWithNote;
  saturday?: DayEnablementWithNote;
}


export interface HrCompPayInfo {
  practiceManager?: string;
  additionalAuthorizedApprovers?: string;
  hoursOfOperation: WeekCheck;
  overnightShifts?: "YES"|"NO";
  shiftDifferentialPay?: "YES"|"NO";
  shiftDifferentialApply?:  Array<"HOURLY_FT_EMPLOYEES" | "HOURLY_PT_EMPLOYEES" | "HOURLY_PER_DIEM_EMPLOYEES" | "SALARIED_EMPLOYEES">;

  shiftDifferentialApplyDayOfWeek?: Array<any>;

  shiftDifferentialRate: {
    type?: "HOURLY_ADD"| "HOURLY_PERCENT" | "BONUS";
    amount?: number;
  }

  shiftDifferentialNotes?: string;

  workForCommission?: "YES"|"NO";
  workForCommissionDescription?: string;


  paidBreaks?: "YES"|"NO";
  paidBreaksNotes?: string;

  fullRemoteExistance?: "YES"|"NO";
  stateRemoteWorkIn?: string;
  currentPayrollSystem?: string;
  regularPayCycle?: "WEEKLY"|"BI_WEEKLY";

  firstDayOfPayCycle?: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  lastDayOfPayCycle?: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  benefitDeductions?: "BI_WEEKLY"|"TWICE_MONTH"|"NA";

  petBenefit?: "YES"|"NO";
  petBenefitNote?: string;

  monthlyAllowances?: "YES"|"NO";
  monthlyAllowancesNote?: string;
  otherBenefits?: "YES"|"NO";
  otherBenefitsNote?: string;
  holidayList? : Array<string>;
  standardHolidayHours?: "YES"|"NO";
  standardHolidayHoursNote?: string;
  standardHolidayPayHours?: number;

}
export interface HrNewForm {
  generalInfo?: {
    practiceName?: string;
    benefitPlanYear?: string;
    insuranceOfferedTypes?: Array<string>;
    lifeDisabilityInsuranceOfferedTypes?: Array<string>;
    lifeDisabilityInsuranceOfferedTypesOtherNote?: string;
    eligibleForBenefits?: string;
  }
}

export interface HrForm {
  benefitInfo?: any;
  compInfo?: any;
  empInfo?: any;


  projectId?: string;
  userId?: string;
  lastSavedOn?: Date;
  status?: string;
}

export interface HrPayrollForm {
  compPayInfo?: HrCompPayInfo;
  projectId?: string;
  userId?: string;
  lastSavedOn?: Date;
  status?: string;
}


export interface HrFormN {
  projectId?: string;
  userId?: string;
  lastSavedOn?: Date;
  status?: string;
  generalInfo?: HrGeneralInfo;
  planInfo?: HrPlanInfo;
  planCostInfo?: HrPlanCostInfo;
  retirementPlanInfo?: HrRetirementPlanInfo;
  //
  leaveInfo?: HrLeaveInfo;
  compAndPtoInfo?: HrCompAndPtoInfo;
  employeeInfo?: HrEmployeeInfo;
}

export interface HrGeneralInfo {
  practiceName: string;
  benefitPlanYear: BenefitPlanYear;
  benefitPlanYearOther?: string;

  insuranceTypes: string[];
  lifeDisabilityBenefitTypes: string[];
  lifeDisabilityBenefitTypesOtherNote?: string;

  eligibleForBenefits: string[];
  eligibleForBenefitsOtherNote?: string;

  hoursPerWeekForEligibility: number;
  hasWaitingPeriod: YesNo;
  waitingPeriodExplanation?: string;
  benefitsBrokerNameAndContact?: string;
  hasEmployeesOnCOBRA: YesNo;
}

export enum BenefitPlanYear {
  FROM_JAN = 'FROM_JAN',
  FROM_JULY = 'FROM_JULY',
  OTHER = 'OTHER'
}

export enum InsuranceType {
  HEALTH = 'Health',
  DENTAL = 'Dental',
  VISION = 'Vision',
  MEDICAL = 'Medical',
  SAVINGS_ACCOUNT = 'Health Savings Account (HSA)',
  REIMBURSEMENT_ACCOUNT = 'Health Reimbursement Account (HRA)',
  FLEXIBLE_SPENDING_ACCOUNT = 'Flexible Spending Account (FSA)'
}


export enum YesNo {
  YES = 'YES',
  NO = 'NO'
}
export interface HrPlanCostInfo {
  employeeInsurance?: string;
  employeeFamilyInsurance?: string;
  employerContributionToHealth?: string;
  employerContributionToDental?: string;
  employerContributionToVision?: string;

  employerContributionToHSA: ValueOrNA;
  employerContributionToLife: ValueOrNA;
  employerContributionToSTD: ValueOrNA;
  employerContributionToLTD: ValueOrNA;
  otherBenefits: ValueOrNA;

  specialArrangements?: YesNo;
  specialArrangementsExplanation?: string;
}

export interface ValueOrNA {
  n_a: boolean;
  value?: string;
}

export interface HrEmployeeInfo {
  annualReviews?: string;
  annualReviewsYesNo?: YesNo;
  documentationInEmployeeFile?: string;
  keyEmployeesManagers?: string;
  typicalShifts?: string;
  hiringPositionsAmount?: string;
  hiringPositionsAmountYesNo?: YesNo;
  hiringPositionsPostDuration?: string;
  contractsWithHiringAgencies?: string;
  contractsWithHiringAgenciesYesNo?: YesNo;
  hiringBudgetPerPostion?: string;
  typicalDurationToFillVetPostion?: string;
  licensedTechPostions?: string;
  hiringResponsible?: string;
  companyCreditCardHolders?: string;
  companyCreditCardHoldersYesNo?: YesNo;
  bargainingAgreement?: string;
  bargainingAgreementYesNo?: YesNo;
  tuitionReimbursement?: string;
  tuitionReimbursementYesNo?: YesNo;
  petInsuranceOrBenefit?: string;
  petInsuranceOrBenefitYesNo?: YesNo;

}
export interface HrCompAndPtoInfo {
  vacationPtoPolicy?: string;
  sickTimePolicy?: string;
  vacationCalculationAndDate?: string;
  cellPhoneAllowance?: string;
  cellPhoneAllowanceYesNo?: YesNo;
  specialEmployeeArrangements?: string;
  specialEmployeeArrangementsYesNo?: YesNo;
  specialGrandfatheredArrangements?: string;
  specialGrandfatheredArrangementsYesNo?: YesNo;
  vetsOnProSalBasisExclusion?: string;
  vetsOnProSalBasisExclusionYesNo?: YesNo;
  proSalCompensationCalcPeriod?: string;
  proSalCompensationCalcFormula?: string;
  bonusStructureEmployees?: string;
  bonusStructureEmployeesYesNo?: YesNo;
  hasBonusesAtSpecificDate?: string;
  hasBonusesAtSpecificDateYesNo?: YesNo;
  bonusesBase?: string;
  vetCompensation?: string;
  offerLettersToStaff?: string;
  offerLettersToStaffYesNo?: YesNo;
  contractsWithVeterinarians?: string;
  providedRaisesFreqAndWhen?: string;
  lastRaisesDate?: string;

}
export interface HrLeaveInfo {
  workersCompClaimPending?: YesNo;
  workersCompClaimPendingExplanation?: string;
  employeesOnLeave: LeaveEmployee[];
  leavesHandleType?: string;
  parentalLeaveBenefit?: string;
  parentalLeaveBenefitYesNo?: YesNo;
}

export interface LeaveEmployee {
  name?: string;
  lastDayOfWork?: string;
  expectedReturnDate?: string;
}

export interface HrPlanInfo {
  medicalProvider: ProviderNamePlanNumber;
  dentalProvider: ProviderNamePlanNumber;
  visionProvider: ProviderNamePlanNumber;
  hsaProvider: ProviderNamePlanNumber;
  hraProvider: ProviderNamePlanNumber;
  fsaProviderPlanNumber: FSAProviderPlanNumber;
  commuterBenefitsProvider: string;
  bliADDProvider: ProviderNamePlanNumber;
  vlaDDProvider: ProviderNamePlanNumber;
  shortTermDisabilityProvider: ProviderNamePlanNumber;
  longTermDisabilityProvider: ProviderNamePlanNumber;
  otherInsuranceProvider: ProviderNamePlanNumber;
  otherInsuranceProviderNote?: string;
}

export interface ProviderNamePlanNumber {
  notAvailable?: boolean;
  name?: string;
  planNumber?: string;
}

export interface FSAProviderPlanNumber {
  notAvailable?: boolean;
  provider?: string;
  planNumber?: string;
}

export interface HrRetirementPlanInfo {
  planType: string[];
  brokerNameAndContact: string;
  retirementPlanOption: string;
  retirementPlanMatchValue?: string;
  retirementPlanGiveValue?: string;
  eligibilityCriteriaMatch?: string;

  iraContributionType?: string;
  iraContributionTypePreValue?: string;
  iraContributionTypePostValue?: string;

  eligibilityCriteria401k?: string;
  hasVestingSchedule: YesNo;
  vestingScheduleExplanation?: string;
  activeLoans: YesNo;
  activeLoansExplanation?: string;
}

// Enum for RetirementPlanType
export enum RetirementPlanType {
  K401 = '401 K',
  SIMPLE_IRA = 'Simple IRA',
  ROTH_IRA = 'Roth IRA',
  NONE = 'None'
}
