
<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">COMPENSATION &amp; PTO INFORMATION</div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your Vacation / PTO Policy?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vacationPtoPolicy" [(ngModel)]="compAndPtoInfo.vacationPtoPolicy"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your Sick Time Policy?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_sickTimePolicy" [(ngModel)]="compAndPtoInfo.sickTimePolicy"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How is vacation calculated? If granted on a date, what is the date?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vacationCalculationAndDate" [(ngModel)]="compAndPtoInfo.vacationCalculationAndDate"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Does anyone in the hospital receive a monthly cell phone allowance?<br/>If yes, who and how much?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_cellPhoneAllowanceYes" id="compandptoinfo_cellPhoneAllowanceYes" value="YES" [(ngModel)]="compAndPtoInfo.cellPhoneAllowanceYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_cellPhoneAllowanceYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_cellPhoneAllowance" placeholder="If yes, who and how much?" [disabled]="compAndPtoInfo.cellPhoneAllowanceYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.cellPhoneAllowance" name="compandptoinfo_cellPhoneAllowanceexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_cellPhoneAllowanceNo" id="compandptoinfo_cellPhoneAllowanceNo" value="NO" [(ngModel)]="compAndPtoInfo.cellPhoneAllowanceYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_cellPhoneAllowanceNo">No</label>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_cellPhoneAllowance" [(ngModel)]="compAndPtoInfo.cellPhoneAllowance"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any special employee arrangements we should be aware of?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_specialEmployeeArrangementsYes" id="compandptoinfo_specialEmployeeArrangementsYes" value="YES" [(ngModel)]="compAndPtoInfo.specialEmployeeArrangementsYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_specialEmployeeArrangementsYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialEmployeeArrangements" placeholder="If yes, explain" [disabled]="compAndPtoInfo.specialEmployeeArrangementsYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.specialEmployeeArrangements" name="compandptoinfo_specialEmployeeArrangementsexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_specialEmployeeArrangementsNo" id="compandptoinfo_specialEmployeeArrangementsNo" value="NO" [(ngModel)]="compAndPtoInfo.specialEmployeeArrangementsYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_specialEmployeeArrangementsNo">No</label>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialEmployeeArrangements" [(ngModel)]="compAndPtoInfo.specialEmployeeArrangements"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any special grandfathered arrangements we should be made aware of?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_specialGrandfatheredArrangementsYes" id="compandptoinfo_specialGrandfatheredArrangementsYes" value="YES" [(ngModel)]="compAndPtoInfo.specialGrandfatheredArrangementsYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_specialGrandfatheredArrangementsYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialGrandfatheredArrangements" placeholder="If yes, explain" [disabled]="compAndPtoInfo.specialGrandfatheredArrangementsYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.specialGrandfatheredArrangements" name="compandptoinfo_specialGrandfatheredArrangementsexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_specialGrandfatheredArrangementsNo" id="compandptoinfo_specialGrandfatheredArrangementsNo" value="NO" [(ngModel)]="compAndPtoInfo.specialGrandfatheredArrangementsYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_specialGrandfatheredArrangementsNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialGrandfatheredArrangements" [(ngModel)]="compAndPtoInfo.specialGrandfatheredArrangements"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any staff excluding Vets on Pro Sal Basis?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_vetsOnProSalBasisExclusionYes" id="compandptoinfo_vetsOnProSalBasisExclusionYes" value="YES" [(ngModel)]="compAndPtoInfo.vetsOnProSalBasisExclusionYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_vetsOnProSalBasisExclusionYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_vetsOnProSalBasisExclusion" placeholder="If yes, explain" [disabled]="compAndPtoInfo.vetsOnProSalBasisExclusionYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.vetsOnProSalBasisExclusion" name="compandptoinfo_vetsOnProSalBasisExclusionexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_vetsOnProSalBasisExclusionNo" id="compandptoinfo_vetsOnProSalBasisExclusionNo" value="NO" [(ngModel)]="compAndPtoInfo.vetsOnProSalBasisExclusionYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_vetsOnProSalBasisExclusionNo">No</label>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vetsOnProSalBasisExclusion" [(ngModel)]="compAndPtoInfo.vetsOnProSalBasisExclusion"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How often is pro-Sal compensation calculated?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_proSalCompensationCalcPeriod" [(ngModel)]="compAndPtoInfo.proSalCompensationCalcPeriod"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Provide formula for calculating Pro-Sal compensation</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_proSalCompensationCalcFormula" [(ngModel)]="compAndPtoInfo.proSalCompensationCalcFormula"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any Employees on a bonus Structure?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_bonusStructureEmployeesYes" id="compandptoinfo_bonusStructureEmployeesYes" value="YES" [(ngModel)]="compAndPtoInfo.bonusStructureEmployeesYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_bonusStructureEmployeesYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_bonusStructureEmployees" placeholder="If yes, explain" [disabled]="compAndPtoInfo.bonusStructureEmployeesYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.bonusStructureEmployees" name="compandptoinfo_bonusStructureEmployeesexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_bonusStructureEmployeesNo" id="compandptoinfo_bonusStructureEmployeesNo" value="NO" [(ngModel)]="compAndPtoInfo.bonusStructureEmployeesYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_bonusStructureEmployeesNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_bonusStructureEmployees" [(ngModel)]="compAndPtoInfo.bonusStructureEmployees"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide bonuses at any specific time during the year?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_hasBonusesAtSpecificDateYes" id="compandptoinfo_hasBonusesAtSpecificDateYes" value="YES" [(ngModel)]="compAndPtoInfo.hasBonusesAtSpecificDateYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_hasBonusesAtSpecificDateYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_hasBonusesAtSpecificDate" placeholder="If yes, explain" [disabled]="compAndPtoInfo.hasBonusesAtSpecificDateYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.hasBonusesAtSpecificDate" name="compandptoinfo_hasBonusesAtSpecificDateexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_hasBonusesAtSpecificDateNo" id="compandptoinfo_hasBonusesAtSpecificDateNo" value="NO" [(ngModel)]="compAndPtoInfo.hasBonusesAtSpecificDateYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_hasBonusesAtSpecificDateNo">No</label>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_hasBonusesAtSpecificDate" [(ngModel)]="compAndPtoInfo.hasBonusesAtSpecificDate"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, what are bonuses based on?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_bonusesBase" [(ngModel)]="compAndPtoInfo.bonusesBase"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How are vets compensated?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vetCompensation" [(ngModel)]="compAndPtoInfo.vetCompensation"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide offer letters to staff, excluding vets, upon hire?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_offerLettersToStaffYes" id="compandptoinfo_offerLettersToStaffYes" value="YES" [(ngModel)]="compAndPtoInfo.offerLettersToStaffYesNo"/>
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_offerLettersToStaffYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="compandptoinfo_offerLettersToStaff" placeholder="If yes, explain" [disabled]="compAndPtoInfo.offerLettersToStaffYesNo==='NO'"  [(ngModel)]="compAndPtoInfo.offerLettersToStaff" name="compandptoinfo_offerLettersToStaffexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="compandptoinfo_offerLettersToStaffNo" id="compandptoinfo_offerLettersToStaffNo" value="NO" [(ngModel)]="compAndPtoInfo.offerLettersToStaffYesNo" />
            <label class="form-check-label col-form-label-sm" for="compandptoinfo_offerLettersToStaffNo">No</label>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="col-sm-9">-->
<!--      <input type="text" class="form-control form-control-sm" id="compandptoinfo_offerLettersToStaff" [(ngModel)]="compAndPtoInfo.offerLettersToStaff"/>-->
<!--    </div>-->

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you require contracts with your Veterinarians?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_contractsWithVeterinarians" [(ngModel)]="compAndPtoInfo.contractsWithVeterinarians"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are raises provided to staff?<br/>If so, how often?<br/>If annually, when is it provided?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_providedRaisesFreqAndWhen" [(ngModel)]="compAndPtoInfo.providedRaisesFreqAndWhen"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">When were the last raises given?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_lastRaisesDate" [(ngModel)]="compAndPtoInfo.lastRaisesDate"/>
    </div>
  </div>
</div>
